import { Grid } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

export default function Paragraph({ children, className = "", md = 8 }) {
  return (
    <Grid container>
      <Grid item md={(12 - md) / 2} className="hidden md:block"></Grid>
      <Grid item md={md} xs={12} className={className}>
        {children}
      </Grid>
    </Grid>
  );
}
